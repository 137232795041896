<template>
  <div>
    <p>
      深圳市美亚发展有限公司及其关联公司（下文简称“美亚”、“我们”和“我们的”）深知您隐私信息的重要性，并会尊重您的隐私。请在向美亚提交个人数据之前，仔细阅读本《隐私政策》（下文简称“本政策”）。本政策适用于显示本隐私政策、或链接至本隐私政策的美亚网站和产品、设备或应用程序（统称为我们的“服务”），包括但不限于美亚商城、美亚官网、美亚公众号等服务。美亚提供服务中包含的所有文字、图片、用户界面、可视化界面、照片、商标、徽标、声音、音乐、艺术作品和计算机代码(统称“内容”)，包括但不限于此类内容的设计、结构、选择、搭配、表达、外观、风格以及排列形式，均由美亚所有、控制或授权，或者授权给美亚，并且受到商业外观、版权、专利和商标法以及各种其他知识产权和反不正当竞争法的保护。
      本政策阐述了美亚将会如何处理您的数据（无论是否为个人数据），但可能并不涉及所有可能的数据处理情境。有关收集产品或服务特定数据的信息可能由美亚在补充政策中，或者在收集数据时提供的通知中向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用。
      美亚有权随时决定更改、修改、添加或删除本隐私政策的部分内容。您可以定期检查本隐私政策，以了解是否发生了更改。阅读本政策的过程中，如果您不同意本政策或其中任何内容，您应立即停止使用美亚提供的相关服务。如果您使用美亚提供的相关服务，即意味着您认可本声明全部内容。
      我们制定本政策的目的在于帮助您了解以下内容：
    </p>
    <ul>
      <li>一、美亚如何收集和使用您的个人数据</li>
      <li>二、美亚如何使用 Cookie 和同类技术</li>
      <li>三、美亚如何披露您的个人数据</li>
      <li>四、如何访问或修改您的个人数据</li>
      <li>五、未成年人个人信息保护</li>
      <li>六、美亚如何留存和保护您的个人数据</li>
      <li>七、第三方提供商及其服务 八、本政策如何更新</li>
    </ul>
    <h1>一、美亚如何收集和使用您的个人数据</h1>
    <p>
      个人数据是指单独使用或结合其他信息使用时能够确定个人身份的信息。此类数据会在您使用我们的网站、产品或服务以及与我们互动时由您直接提交给我们，例如：
    </p>

    <ul>
      <li>
        1.当您注册会员以及在使用美亚或其他平台方提供的相关服务时填写、提交的信息，如您的姓名、性别、出生年月日、身份证号码、电话号码、电子邮箱、地址、头像、昵称、位置等相关信息。
      </li>
      <li>
        2.当您选择购买、送货及安装等服务时，我们可能会询问您的姓名、联系信息、配送地址和账单地址以及银行卡信息以便处理您的订单。
      </li>
      <li>
        3.当您下载或使用客户端软件，美亚可能会读取与您设备相关的信息，包括但不限于硬件型号、IMEI号码和其他唯一设备标识符、MAC地址、IP地址、操作系统版本以及用于访问服务的设备的设置。
      </li>
      <li>
        4.当您使用美亚物联网设备时，美亚可能在不另行通知您的情况下读取您的使用情况，包括但不限于设备型号、设备运行状态、设备功能使用频次、物联网设备的日志信息、设备上传的测量数据或其他使用信息，某些设备内置摄像头可能会拍摄设备的使用情况等。
      </li>
      <li>
        5.在您使用美亚服务或访问美亚网站时，美亚自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据。
      </li>
      <li>
        6.位置信息（仅适用于特定服务/功能）：您下载或使用客户端软件或访问网页，以及使用某些美亚服务、物联网设备时，美亚可能会读取与您位置相关的各类信息，例如设备的GPS信号或附近的WiFi接入点和手机信号发射塔的相关信息、国家代码、城市代码、移动网络代码、小区标识、经纬度信息和语言设置，这些信息可能会在您使用某些服务时传送给我们。
      </li>
    </ul>

    <p>
      在法律允许的情况下，我们还会从公用和商用第三方来源获取有关数据，例如通过从其他公司购买统计数据、从第三方电商平台获取订单数据来支持我们的服务，您参与问卷调查时向我们发送的问卷答复信息、参加用户体验计划以及您与我们以及您与美亚合作伙伴之间互动时我们收集的相关信息。我们收集的数据取决于您与美亚互动的方式，包括访问的网站或者使用的产品和服务等，比如姓名、性别、企业名称、职位、地址、电子邮箱、电话号码、登录信息（帐号和密码）、照片、证件信息等。在某些情况下，您可以选择不向美亚提供个人数据，但如果您选择不提供，美亚可能无法为您提供相关产品或服务，也无法回应或解决您所遇到的问题。
      我们可能将您的个人数据用于以下目的：
    </p>

    <ul>
      <li>
        1.创建账户，将通过网站或移动设备创建账号时收集的个人信息用于建立用户的个人账户和资料页。
      </li>
      <li>
        2.实现您的交易或服务请求，如：履行订单，与电子商务订单相关的信息可用于处理订购单和相关的售后服务，包括客户支持和重新发货。收件人信息，包括姓名、地址、电话号码和邮政编码将用于送货。电子邮件地址用于向用户发送包裹跟踪信息。购买物品的清单用于打印发票，并允许客户确定包裹中的物品。当您使用美亚客户服务电话、微信公众号及其他自助服务平台要求美亚为您提供产品安装、维修、咨询服务时，根据您的不同诉求，需要您向我们提供您的姓名、地址、联系方式、拟安装或维修的产品等信息，以便美亚及时为您提供所需服务。
      </li>
      <li>
        3.激活或验证产品或服务，控制、使用设备。您控制您的部分设备需要使用APP登录账户，并正常完成设备的配网、绑定流程。完成这些流程后，您即可通过APP对设备进行远程控制或局域网内控制。部分设备需要激活后方可使用。激活过程需要使用相关的设备信息，包括：MAC地址、机器编码、WIFI密码等。若激活失败，则我们可能读取相关的设备信息和日志信息用于评估激活失败的原因。同时，您控制您的联网设备可能需要使用用户名/密码等信息。
      </li>
      <li>
        4.提供基于位置的服务。在使用某些服务时，我们或第三方服务供应商可能会使用位置信息以为您提供正确版本的服务，并且提供准确的位置，以使您获得尽可能好的用户体验，例如通过读取您的位置了解与您最近的仓库并预估送货时间，或在不同海拔、地区使用不同程序保证设备功能的正常实现等。
      </li>
      <li>
        5.向您发送有关您可能感兴趣的产品和服务的信息，包括但不限于邀请您参与美亚活动（如促销活动）、市场调查或满意度调查，或向您发送营销信息，如您参与抽奖、竞赛或类似推广活动，我们会将您提供的信息用于管理此类活动，如您中奖，可能会将您的手机号或昵称脱敏后以写入中奖名单并公布。若该活动是我们和第三方联合推广的，我们可能与其共享活动过程中产生的、为完成活动所必要的个人信息（参加活动的用户数、中奖名单、中奖人联系方式），以便第三方能及时向您发放奖品或为您提供服务。
      </li>
      <li>6.向您发送重要通知，如操作系统或应用程序更新和安装的通知。</li>
      <li>7.为您提供个性化用户体验和个性化内容，如获得额外产品售后服务。</li>
      <li>
        8.允许您使用第三方功能。我们提供的产品中，其中有些功能是由第三方服务供应商运营的。因此，我们需要将您的信息与第三方分享才能够使您体验到完整的功能。例如，在您使用我们的服务搜索菜谱、音频、视频、食材百科等内容时，我们可能需要把您的搜索请求传给第三方服务提供商。
      </li>
      <li>
        9.收集用户使用反馈。您提供的使用反馈能够帮助改进我们的服务、设备功能等。为了跟踪您选择提供的反馈，可能会使用您所提供的个人信息与您联系，并保留记录。
      </li>
      <li>10.开展内部审计、数据分析和研究，改善我们的产品和服务。</li>
      <li>11.分析业务运营效率并衡量市场份额。</li>
      <li>
        12.同步、共享和存储您上传或下载的数据以及执行上传和下载所需的数据。
      </li>
      <li>13.遵从和执行适用的法律要求，相关的行业标准或我们的政策。</li>
    </ul>

    <p>
      美亚还可能收集和使用非识别性数据。非识别性数据是指无法用于确定个人身份的数据。例如，美亚会收集汇总的统计数据，例如网站访问量、物联网设备功能使用频率等。美亚收集此数据的目的在于了解用户如何使用自己的网站、产品和服务。借此，美亚可以改善自己的服务，更好地满足客户需求。美亚可能会自行决定出于其他目的收集、使用、处理、转移或披露非识别性数据。
      我们会尽力隔离您的个人数据和非识别性数据，并单独使用这两种数据。如果个人数据掺杂了非识别性数据，依旧会被视作个人数据处理。当美亚处理您的个人数据时我们会遵从适用的法律的要求基于适当的合法性基础予以处理，包括：
    </p>
    <ul>
      <li>1.当响应您的交易或服务请求时为履行合同处理您的个人数据；</li>
      <li>2.基于您的同意处理您的个人数据；</li>
      <li>
        3.当与您联系、进行营销或市场调查，为改善我们的产品和服务，执行与改善我们的防损和反欺诈计划等目的处理您的个人数据时我们将基于我们或第三方的合法利益。
      </li>
      <li>4.我们还可能基于遵从和执行法律义务处理您的个人数据。</li>
    </ul>
    <h1>
      二、美亚如何使用 Cookie 和同类技术
    </h1>
    <h2>1.Cookie</h2>
    <p>
      为确保网站正常运转，我们有时会在计算机或移动设备上存储名为 Cookie
      的小数据文件。Cookie
      是一种网络服务器存储在计算机或移动设备上的纯文本文件。Cookie
      的内容只能由创建它的服务器检索或读取。每个 Cookie
      对您的网络浏览器或移动应用程序都是唯一的。Cookie
      通常包含标识符、站点名称以及一些号码和字符。借助于
      Cookie，网站能够存储用户偏好或购物篮内的商品等数据。
      美亚启用Cookie的目的与大多数网站或互联网服务提供商启用 Cookie
      的目的一样，即改善用户体验。借助于
      Cookie，网站能够记住用户的单次访问（使用会话 Cookie）或多次访问（使用永久
      Cookie）。借助于
      Cookie，网站能够保存设置，例如计算机或移动设备的语言、字体大小和其他浏览偏好。这意味着，用户无需在每次访问时重新配置用户偏好设置。
    </p>
    <h2>
      2.网站信标和像素标签
    </h2>
    <p>
      我们会在网站上使用网站信标和像素标签等其他同类技术。例如，美亚向您发送的电子邮件可能含有链接至美亚网站内容的点击URL。如果您点击该链接，美亚则会跟踪此次点击，帮助我们了解您的产品和服务偏好并改善客户服务。如果您不希望自己的活动以这种方式被追踪，则可以随时从美亚的寄信名单中退订。
    </p>
    <h2>
      3.移动分析软件
    </h2>
    <p>
      在APP中，我们使用移动分析软件，以更好地了解我们的APP在您的手机中的功能。此款软件可能记录以下信息，如您使用该应用程序的频率、该应用程序内发生的事件、累计使用情况、性能数据及应用程序崩溃发生的位置。
    </p>
    <h2>
      4.SDK的使用
    </h2>
    <p>华为设备管理服务SDK</p>
    <p>
      收集个人信息目的：完成与华为云的端云授权校验，以确保您的产品始终满足HarmonyOS
      Connect 认证的要求。
    </p>
    <p>
      收集个人数据类型：设备相关的设备硬件标识符、设备硬件参数、系统版本信息。
    </p>
    <p>
      第三方SDK隐私声明：
      <a
        href="https://device.harmonyos.com/cn/docs/devicepartner/DevicePartner-Guides/device-privacy-statement-0000001188494819"
        target="blank"
        >关于华为设备管理服务与隐私的声明。
      </a>
    </p>
    <h1>
      三、美亚如何披露、分享您的个人数据
    </h1>
    <p>
      您的个人信息将被严格保密，并且只会与下面列出类别的数据接收者共享。另外，我们只会在征得您同意的情况下与下列情形之外的第三方分享您的信息，并且您将可以选择是否允许我们分享您的信息。
    </p>
    <h2>1.美亚关联公司。</h2>
    <p>
      为便于我们向您提供服务，推荐您可能感兴趣的信息或保护美亚或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会在美亚关联公司之间共享。我们只会共享必要的个人信息，如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。
    </p>
    <h2>2.授权合作伙伴。</h2>
    <p>
      我们可能与可信的授权合作伙伴共享您的信息。我们可能会向授权合作伙伴共享您的账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。目前，我们的授权合作伙伴包括以下类型：
      广告、分析服务类的授权合作伙伴。经销商、供应商、服务提供商和其他合作伙伴。我们的经销商、供应商和服务提供商包括提供技术服务、电信服务、客户服务、风控服务、审计服务、法律服务的机构、金融机构、电子商务公司及平台、线下卖场等。
      对我们与之共享个人信息的公司、组织和个人，我们会与其签署保密协定，并会以适当的方式要求他们按照我们的说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。在个人敏感数据使用上，我们要求第三方采用加密技术，从而更好地保护用户数据。一经发现其违反协议约定将会采取有效措施乃至终止合作。
      遵守法律法规强制性要求、法院命令或政府主管部门要求、维护社会公共利益，或保护我们的客户、美亚或美亚员工的人身和财产安全或合法权益所合理必需的用途。
      单独获得您同意的其他方。除本隐私权政策中描述的共享情况之外，我们也可能在您同意共享或提出共享请求时与第三方共享您的信息。
    </p>
    <h2>3.不需要同意的信息</h2>
    <p>
      我们可能以汇总的形式与第三方共享匿名信息，用于商业目的；我们可能与其共享我们服务的一般使用趋势，例如在购买某些产品或从事某些交易的特定人群中的客户数量。
      根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下共享、转让、公开披露您的个人信息：
      与国家安全、国防安全有关的； 与公共安全、公共卫生、重大公共利益有关的；
      与犯罪侦查、起诉、审判和判决执行等有关的；
      出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
      您自行向社会公众公开的个人信息；
      从合法公开披露的信息中收集到的个人信息的，如合法的新闻报道、政府信息公开等渠道。
      维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障。
      法律法规规定的其他情形。
      根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
      如您不同意我们按照上述条款向第三方提供您的个人信息，请您立即停止使用我方服务。
    </p>
    <h1>
      四、如何访问或修改您的个人数据
    </h1>
    <p>
      您应确保提交的所有个人数据都准确无误。美亚会尽力维护个人数据的准确和完整，并及时更新这些数据。在适用的法律要求的情况下，您可以：
    </p>
    <ul>
      <li>1.有权访问我们持有的关于您的特定的个人数据；</li>
      <li>2.要求我们更新或更正您的不准确的个人数据；</li>
      <li>3.拒绝或限制我们使用您的个人数据；</li>
      <li>4.要求我们删除您的个人数据或主动注销账户。</li>
    </ul>
    <p>
      如果您想行使相关的权利，请通过此邮箱chenzhiyi@mpebedding.com在线反馈给我们。为保障安全，我们可能会先要求您验证自己的身份（如要求您提供书面请求或其他方式），然后再处理您的请求。如果我们有合理依据认为这些请求存在欺骗性、无法实行或损害他人隐私权，我们则会拒绝处理请求。
      当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以清除或实现匿名化。
      您的个人数据删除或账号注销前，请您自行备份并妥善保留账户信息及其他相关数据，妥善保存产品及服务的交易凭证、票据等资料，以免无法享受到相关售后服务或需要额外支付费用。个人数据删除或账号注销后，您可能将无法继续使用我们的服务，您也将无法找回账户中及与账户相关的任何内容或信息。
      当适用的法律要求的情况下，当美亚基于您的同意处理您的个人数据时，您还有权随时撤销您的同意。但撤销同意不会影响撤销前我们基于您的同意处理您个人数据的合法性及效力，也不影响我们基于其他适当的正当性基础处理您的个人数据。
    </p>
    <h1>
      五、未成年人个人信息保护
    </h1>
    <p>
      美亚提供的产品、服务主要面向成人。若您是 14
      周岁以下的未成年人，在使用我们的产品和/或服务前，您应在监护人的陪同下阅读本指引，并应确保已征得您的监护人同意后使用我们的服务并向我们提供您的信息。
      我们会根据国家相关法律法规的规定着重保护未成年人的个人信息。
      如您的监护人不同意您按照本指引使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们。
      对于经父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。
      若您是未成年人的监护人，当您对您所监护的未成年人使用我们的服务或其向我们提供的用户信息有任何疑问时，请您及时与我们联系。我们将根据国家相关法律法规及本指引的规定保护未成年人用户信息的保密性及安全性。如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。
    </p>
    <h1>
      六、美亚如何留存和保护您的个人数据
    </h1>
    <p>
      原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。
      美亚重视个人数据的安全。我们将会在本政策所述目的所需的期限内保留您的个人数据，除非按照法律要求或许可需要延长保留期或受到法律的允许。因为基于不同的场景和产品及服务的不同，数据的存储期可能会有所不同。
      美亚不对因您未能保持个人信息的私密性而导致第三方访问您的个人信息进而造成的安全疏漏承担责任。尽管有上述规定，如果发生互联网其他任何用户未经授权使用您账户的情况或其他任何安全漏洞，您应当立即通知我们。您的协助将有助于我们保护您个人信息的私密性。
    </p>
    <h1>
      七、第三方提供商及其服务
    </h1>
    <p>
      为确保流畅的浏览体验，您可能会收到来自美亚及其合作伙伴外部的第三方（下文简称“第三方”）提供的内容或网络链接。美亚对此类第三方无控制权。您可选择是否访问第三方提供的链接、内容、产品和服务。
      美亚无法控制第三方的隐私和数据保护政策，此类第三方不受到本政策的约束。在向第三方提交个人信息之前，请参见这些第三方的隐私保护政策。
    </p>
    <h1>
      八、本政策如何更新
    </h1>
    <p>
      美亚保留不时更新或修改本政策的权利。如果我们的隐私政策变更，我们会将最新版隐私政策发布在这里。
      最近更新日期：2021年9月
    </p>
  </div>
</template>

<script></script>

<style scoped>
h1 {
  margin-top: 1em;
  font-size: 1.4rem;
}
h2 {
  margin-top: 0.5em;
  font-size: 1.2rem;
}
p {
  margin-top: 0.3em;
}
div {
  padding: 0.8rem;
}
* {
  line-height: 1.5;
}
</style>
